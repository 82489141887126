import {
    Box,
    Button,
    CustomAlert,
    enerbitColors,
    Pagination,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@enerbit/base";
import { StyledTableHeader } from "../../../components/Table/StyledTable";
import { useAgendaStore } from "../../../store";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import PaginationContainer from "../../../components/container/pagination-container";
import { Filters } from "../../../models";
import AgendaTableFilters from "./AgendaTableFilters";
import StatusTag from "../../../components/container/status-tag";

const tableHeaders: string[] = [
    "ID",
    "Nombre",
    "Correo electrónico",
    "Número de teléfono",
    "Tipo",
    "Fecha agendada",
    "Fecha de envío",
    "Estado",
    "Detalle",
];

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
    width: "100px",
};

const noWrapCellStyle: SxProps = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
};

export const agendaStatus: Record<
    string,
    { label: string; font?: string; bgcolor?: string }
> = {
    pending: {
        font: enerbitColors.warning.main,
        label: "Pendiente",
        bgcolor: enerbitColors.warning[100],
    },
    delivered: {
        font: enerbitColors.success.main,
        label: "Entregado",
        bgcolor: enerbitColors.success[100],
    },
    expired: {
        font: enerbitColors.neutral.main,
        label: "Vencido",
        bgcolor: enerbitColors.neutral[100],
    },
    failed: {
        font: enerbitColors.error.main,
        label: "Fallido",
        bgcolor: enerbitColors.error[100],
    },
    session_already_active: {
        font: "#3B82F6",
        label: "Sesión activa",
        bgcolor: "#EAF0FA",
    },
};

export const agendaTypesToName: Record<string, string> = {
    single_property: "Única propiedad",
};

const AgendaTable = () => {
    const {
        loading,
        error,
        searchAgendas,
        agendas,
        currentPage,
        totalAgendas,
        totalPages,
    } = useAgendaStore((state) => state);

    const [filters, setFilters] = useState<Filters>({
        email: "",
        phone_number: "",
        name: "",
        process_id: "",
    });

    const navigate = useNavigate();

    useEffect(() => {
        searchAgendas({ page: 0 });
    }, []);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        searchAgendas({ page: value - 1, filters });
    };

    const handleFiltersChange = (key: string, value: unknown) => {
        setFilters((prev) => {
            const nD = { ...prev };
            nD[key] = value;
            return nD;
        });
    };

    const resetFilters = () => {
        setFilters({
            email: "",
            phone_number: "",
            name: "",
            process_id: "",
        });
        searchAgendas({ page: 0 });
    };

    const handleSearch = () => {
        searchAgendas({ page: 0, filters });
    };

    return (
        <Box>
            <Box sx={{ my: 3 }}>
                <AgendaTableFilters
                    filters={filters}
                    handleFiltersChange={handleFiltersChange}
                    resetFilters={resetFilters}
                    handleSearch={handleSearch}
                />
            </Box>
            {loading && <TableSkeleton rows={20} columns={9} />}
            {error && (
                <CustomAlert
                    severity="error"
                    text="Error listando agendas"
                    onClose={() => {}}
                />
            )}
            {!loading && !error && totalAgendas > 0 && (
                <>
                    <TableContainer sx={{ my: 3 }}>
                        <Table>
                            <TableHead>
                                <StyledTableHeader>
                                    {tableHeaders.map((header, i) => (
                                        <TableCell key={`${i + 1}-${header}`}>
                                            {header}
                                        </TableCell>
                                    ))}
                                </StyledTableHeader>
                            </TableHead>
                            <TableBody>
                                {agendas.map((ag) => (
                                    <TableRow key={ag.id}>
                                        <TableCell sx={noWrapCellStyle}>
                                            {ag.id}
                                        </TableCell>
                                        <TableCell>{ag.name}</TableCell>
                                        <TableCell>{ag.email}</TableCell>
                                        <TableCell>
                                            {ag.phone_country_code}{" "}
                                            {ag.phone_number}
                                        </TableCell>
                                        <TableCell>
                                            {agendaTypesToName[ag.agenda_type]}
                                        </TableCell>
                                        <TableCell>
                                            {moment(ag.send_date).format(
                                                "YYYY-MM-DD HH:mm",
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {ag.sent_date
                                                ? moment(ag.sent_date).format(
                                                      "YYYY-MM-DD HH:mm",
                                                  )
                                                : "Sin enviar"}
                                        </TableCell>
                                        <TableCell>
                                            <StatusTag
                                                fontcolor={
                                                    agendaStatus[ag.status].font
                                                }
                                                bgcolor={
                                                    agendaStatus[ag.status]
                                                        .bgcolor
                                                }
                                            >
                                                {agendaStatus[ag.status].label}
                                            </StatusTag>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                sx={detailButtonProps}
                                                onClick={() =>
                                                    navigate(`/agenda/${ag.id}`)
                                                }
                                            >
                                                Ver detalle
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
        </Box>
    );
};

export default AgendaTable;
