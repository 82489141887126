import {
    Box,
    Button,
    CustomAlert,
    enerbitColors,
    Pagination,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@enerbit/base";
import ProcessTableFilters from "./ProcessTableFilters";
import { StyledTableHeader } from "../../../components/Table/StyledTable";
import { useNavigate } from "react-router-dom";
import { useProcessesStore } from "../../../store/process";
import moment from "moment";
import StatusTag from "../../../components/container/status-tag";
import { useEffect, useState } from "react";
import PaginationContainer from "../../../components/container/pagination-container";
import { Filters } from "../../../models";
import TableSkeleton from "../../../components/Table/TableSkeleton";

const tableHeaders: string[] = [
    "ID",
    "ID Público",
    "Número de teléfono",
    "Fecha de completado",
    "Estado",
    "Detalle",
];

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
    width: "100px",
};

export const processStatus: Record<
    string,
    { label: string; font?: string; bgcolor?: string }
> = {
    in_progress: {
        font: enerbitColors.warning.main,
        label: "En progreso",
        bgcolor: enerbitColors.warning[100],
    },
    completed: {
        font: enerbitColors.success.main,
        label: "Completado",
        bgcolor: enerbitColors.success[100],
    },
    expired: {
        font: enerbitColors.error.main,
        label: "Expirado",
        bgcolor: enerbitColors.error[100],
    },
};

const ProcessTable = () => {
    const navigate = useNavigate();
    const {
        loading,
        error,
        searchProcesses,
        processes,
        currentPage,
        totalProcesses,
        totalPages,
    } = useProcessesStore((state) => state);

    const [filters, setFilters] = useState<Filters>({
        public_id: "",
        entrypoint: "",
        status: "",
    });

    useEffect(() => {
        searchProcesses({ page: 0 });
    }, []);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        searchProcesses({ page: value - 1, filters });
    };

    const handleFiltersChange = (key: string, value: unknown) => {
        setFilters((prev) => {
            const nD = { ...prev };
            nD[key] = value;
            return nD;
        });
    };

    const resetFilters = () => {
        setFilters({
            public_id: "",
            entrypoint: "",
            status: "",
        });
        searchProcesses({ page: 0 });
    };

    const handleSearch = () => {
        searchProcesses({ page: 0, filters });
    };

    return (
        <Box>
            <Box sx={{ my: 3 }}>
                <ProcessTableFilters
                    filters={filters}
                    handleFiltersChange={handleFiltersChange}
                    resetFilters={resetFilters}
                    handleSearch={handleSearch}
                />
            </Box>
            {loading && <TableSkeleton rows={20} columns={6} />}
            {error && (
                <CustomAlert
                    severity="error"
                    text="Error listando procesos"
                    onClose={() => {}}
                />
            )}
            {!loading && !error && totalProcesses > 0 && (
                <>
                    <TableContainer sx={{ my: 3 }}>
                        <Table>
                            <TableHead>
                                <StyledTableHeader>
                                    {tableHeaders.map((header, i) => (
                                        <TableCell key={`${i + 1}-${header}`}>
                                            {header}
                                        </TableCell>
                                    ))}
                                </StyledTableHeader>
                            </TableHead>
                            <TableBody>
                                {processes.map((pr) => (
                                    <TableRow
                                        sx={{
                                            ".MuiTableCell-root": {
                                                padding: "8px 16px !important",
                                            },
                                        }}
                                        key={pr.id}
                                    >
                                        <TableCell>{pr.id}</TableCell>
                                        <TableCell>{pr.public_id}</TableCell>
                                        <TableCell>{pr.entrypoint}</TableCell>
                                        <TableCell>
                                            {pr.completed_at
                                                ? moment(
                                                      pr.completed_at,
                                                  ).format("YYYY-MM-DD HH:mm")
                                                : "Sin completar"}
                                        </TableCell>
                                        <TableCell>
                                            <StatusTag
                                                fontcolor={
                                                    processStatus[pr.status].font
                                                }
                                                bgcolor={
                                                    processStatus[pr.status]
                                                        .bgcolor
                                                }
                                            >
                                                {processStatus[pr.status].label}
                                            </StatusTag>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                sx={detailButtonProps}
                                                onClick={() =>
                                                    navigate(
                                                        `/process/${pr.id}`,
                                                    )
                                                }
                                            >
                                                Ver detalle
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
        </Box>
    );
};

export default ProcessTable;
