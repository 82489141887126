import { HashRouter, Route, Routes } from "react-router-dom";
import ConversionList from "../pages/list";
import ProcessDetail from "../pages/process-detail";
import AgendaDetail from "../pages/agenda-detail";

const Router = () => {
    return (
        <HashRouter basename="/conversion">
            <Routes>
                <Route path="/" element={<ConversionList />} />
                <Route path="/process/:id" element={<ProcessDetail />} />
                <Route path="/agenda/:id" element={<AgendaDetail />} />
            </Routes>
        </HashRouter>
    );
};

export default Router;
