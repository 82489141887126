import { create } from "zustand";
import { Process, SearchProcessesParams } from "../models";
import { getProcessDetail, serachProcesses } from "../services";

interface ProcessesStore {
    loading: boolean;
    error: boolean;
    processes: Process[];
    processDetail: Process | null;
    // agenda: IAgendaDetail | null;
    totalPages: number;
    currentPage: number;
    totalProcesses: number;
    searchProcesses: (props: SearchProcessesParams) => Promise<void>;
    getProcessDetail: (id: string) => Promise<void>;
}

export const useProcessesStore = create<ProcessesStore>()((set) => ({
    loading: false,
    error: false,
    processes: [],
    totalPages: 0,
    currentPage: 0,
    totalProcesses: 0,
    processDetail: null,
    searchProcesses: async ({ page, filters }: SearchProcessesParams) => {
        try {
            set({ loading: true, error: false });
            const res = await serachProcesses({ page, filters });
            set({
                processes: res.items,
                currentPage: res.page,
                totalProcesses: res.total,
                totalPages: res.pages,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
    getProcessDetail: async (id: string) => {
        set({ error: false, loading: true });
        try {
            const process = await getProcessDetail(id);
            set({ processDetail: process });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
