import ShortUniqueId from "short-unique-id";

export const generateRandomId = () => {
    const { randomUUID } = new ShortUniqueId({ length: 10 });

    return randomUUID();
};

export const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    // setTimeout(() => {}, 2000);
};
