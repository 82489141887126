import { Divider, enerbitColors, Typography } from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { IAgendaDetail } from "../../../models";
import PropertyItem from "./PropertyItem";

interface Props {
    agenda: IAgendaDetail;
}

const Properties = ({ agenda }: Props) => {
    return (
        <SectionContainer>
            <Typography
                sx={{
                    color: enerbitColors.primary.main,
                    fontWeight: 700,
                    fontSize: "18px",
                }}
            >
                Propiedades
            </Typography>
            <Divider sx={{ my: 2, mx: "-12px" }} />
            {agenda.properties.map((property, index) => (
                <PropertyItem
                    key={property.id}
                    property={property}
                    index={index}
                />
            ))}
        </SectionContainer>
    );
};

export default Properties;
