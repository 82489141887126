import {
    Box,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Typography,
} from "@enerbit/base";
import { useNavigate, useParams } from "react-router-dom";
import Backbutton from "../../components/Buttons/back-button";
import SectionContainer from "../../components/container/section-container";
import PropertyForm from "./components/PropertyForm";
import IdentityForm from "./components/IdentityForm";
import { useProcessesStore } from "../../store/process";
import { useEffect } from "react";
import Checkout from "./components/Checkout";
import StatusTag from "../../components/container/status-tag";
import { processStatus } from "../list/components/ProcessTable";

const ProcessDetail = () => {
    const { loading, processDetail, error, getProcessDetail } =
        useProcessesStore((state) => state);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/");
    };

    useEffect(() => {
        if (!id) return;

        getProcessDetail(id);
    }, [id]);

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Backbutton onClick={handleBack} />
                <Box>
                    <Typography sx={{ color: enerbitColors.neutral[700] }}>
                        Proceso
                    </Typography>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "28px",
                            fontWeight: 700,
                        }}
                    >
                        {id}
                    </Typography>
                </Box>
                {processDetail && (
                    <StatusTag
                        fontcolor={processStatus[processDetail.status].font}
                        bgcolor={processStatus[processDetail.status].bgcolor}
                    >
                        {processStatus[processDetail.status].label}
                    </StatusTag>
                )}
            </Box>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <CustomAlert
                    text="Error cargando detalles del proceso."
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {!loading && !error && processDetail && (
                <>
                    <SectionContainer sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                                fontSize: "18px",
                            }}
                        >
                            Paso 1: Información inicial
                        </Typography>
                        <Divider sx={{ my: 2, mx: "-12px" }} />
                        <PropertyForm process={processDetail} />
                    </SectionContainer>
                    <SectionContainer sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                                fontSize: "18px",
                            }}
                        >
                            Paso 2: Verificación de identidad
                        </Typography>
                        <Divider sx={{ my: 2, mx: "-12px" }} />
                        <IdentityForm process={processDetail} />
                    </SectionContainer>
                    <SectionContainer sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                                fontSize: "18px",
                            }}
                        >
                            Paso 3: Confirmación y firma de la ofera comercial
                        </Typography>
                        <Divider sx={{ my: 2, mx: "-12px" }} />
                        <Checkout process={processDetail} />
                    </SectionContainer>
                </>
            )}
        </Box>
    );
};

export default ProcessDetail;
