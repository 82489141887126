export const serviceLocationNames: Record<string, string> = {
    home: "Hogar",
    company: "Empresa",
};

export const buildingRoleNames: Record<string, string> = {
    owner: "Propietario",
    lessee: "Inquilino",
    agent: "Administrador",
};

export const documentTypesNames: Record<string, string> = {
    cc: "Cédula de ciudadanía",
};
