import {
    Box,
    InputLabel,
    FormControl,
    SearchIcon,
    Button,
    IconButton,
    CachedIcon,
} from "@enerbit/base";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import AbcIcon from '@mui/icons-material/Abc';
import { Filters } from "../../../models";

interface Props {
    filters: Filters;
    handleFiltersChange: (key: string, value: string) => void;
    resetFilters: () => void;
    handleSearch: () => void;
}

const AgendaTableFilters = ({
    filters,
    handleFiltersChange,
    resetFilters,
    handleSearch,
}: Props) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box>
                    <InputLabel sx={{ mb: 1 }}>Nombre</InputLabel>
                    <FormControl>
                        <CustomTextField
                            fullWidth
                            value={filters.name}
                            onChange={(e) =>
                                handleFiltersChange("name", e.target.value)
                            }
                            sx={{ width: "280px" }}
                            InputProps={{
                                startAdornment: <SearchIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <InputLabel sx={{ mb: 1 }}>Número de teléfono</InputLabel>
                    <FormControl>
                        <CustomTextField
                            fullWidth
                            value={filters.phone_number}
                            onChange={(e) =>
                                handleFiltersChange(
                                    "phone_number",
                                    e.target.value,
                                )
                            }
                            sx={{ width: "280px" }}
                            InputProps={{
                                startAdornment: <LocalPhoneIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <InputLabel sx={{ mb: 1 }}>Email</InputLabel>
                    <FormControl>
                        <CustomTextField
                            fullWidth
                            value={filters.email}
                            onChange={(e) =>
                                handleFiltersChange("email", e.target.value)
                            }
                            sx={{ width: "280px" }}
                            InputProps={{
                                startAdornment: <MailIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <InputLabel sx={{ mb: 1 }}>Id del proceso</InputLabel>
                    <FormControl>
                        <CustomTextField
                            fullWidth
                            value={filters.email}
                            onChange={(e) =>
                                handleFiltersChange(
                                    "process_id",
                                    e.target.value,
                                )
                            }
                            sx={{ width: "280px" }}
                            InputProps={{
                                startAdornment: <AbcIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3 }}
                        onClick={handleSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Box>
                <Box>
                    <IconButton
                        color="primary"
                        sx={{ mt: 3 }}
                        onClick={resetFilters}
                    >
                        <CachedIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default AgendaTableFilters;
