import { Box, Divider, enerbitColors, Grid, Typography } from "@enerbit/base";
import { IAgendaDetail } from "../../../models";
import moment from "moment";
import SectionContainer from "../../../components/container/section-container";
import { agendaTypesToName } from "../../list/components/AgendaTable";
import CopyButton from "../../process-detail/components/CopyButton";

interface Props {
    agenda: IAgendaDetail;
}

const generalInfo: {
    label: string;
    key: keyof IAgendaDetail;
    format?: (data: IAgendaDetail) => unknown;
}[] = [
    { label: "ID", key: "id" },
    { label: "Nombre", key: "name" },
    { label: "Correo electrónico", key: "email" },
    {
        label: "Número de teléfono",
        key: "phone_number",
        format: (data: IAgendaDetail) => {
            return `${data.phone_country_code} ${data.phone_number}`;
        },
    },
    {
        label: "Tipo",
        key: "agenda_type",
        format: (data: IAgendaDetail) => {
            return agendaTypesToName[data.agenda_type];
        },
    },
    {
        label: "Fecha agendada",
        key: "send_date",
        format: (data: IAgendaDetail) => {
            return moment(data.send_date).format("YYYY-MM-DD HH:mm");
        },
    },
    {
        label: "Fecha de envio",
        key: "sent_date",
        format: (data: IAgendaDetail) => {
            return moment(data.sent_date).format("YYYY-MM-DD HH:mm");
        },
    },
];

const GeneralInfo = ({ agenda }: Props) => {
    return (
        <SectionContainer>
            <Typography
                sx={{
                    color: enerbitColors.primary.main,
                    fontWeight: 700,
                    fontSize: "18px",
                }}
            >
                Información general
            </Typography>
            <Divider sx={{ my: 2, mx: "-12px" }} />
            <Grid container rowSpacing={2} columnSpacing={2}>
                {generalInfo.map((gI) => (
                    <Grid item xs={3} key={gI.key}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <CopyButton
                                text={
                                    gI.format
                                        ? (gI.format(agenda) as string)
                                        : (agenda[gI.key] as string)
                                }
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        color: enerbitColors.neutral.main,
                                    }}
                                >
                                    {gI.label}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: enerbitColors.neutral[900],
                                        fontWeight: 700,
                                    }}
                                >
                                    {gI.format
                                        ? gI.format(agenda)
                                        : agenda[gI.key]}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </SectionContainer>
    );
};

export default GeneralInfo;
