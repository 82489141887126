import {
    Box,
    enerbitColors,
    Grid,
    InputLabel,
    Typography,
} from "@enerbit/base";
import { Process } from "../../../models";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "./CopyButton";
import { documentTypesNames } from "../../../common";
import FileContainer from "../../../components/container/FileContainer";

interface Props {
    process: Process;
}

const IdentityForm = ({ process }: Props) => {
    return (
        <Box>
            {(!process.form_data.step2 ||
                Object.entries(process.form_data.step2).length === 0) && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: enerbitColors.primary.main,
                        }}
                    >
                        Todavía no hay información para mostrar en esta sección.
                    </Typography>
                </Box>
            )}
            {process.form_data.step2 &&
                Object.entries(process.form_data.step2).length > 0 && (
                    <Box>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Tipo documento de identidad
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        documentTypesNames[
                                            process.form_data.step2.docType
                                        ]
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    documentTypesNames[
                                                        process.form_data.step2
                                                            .docType
                                                    ]
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Número documento de identidad
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        process.form_data.step2.documentIdentity
                                            .number
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .documentIdentity.number
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Nombres
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        process.form_data.step2.documentIdentity
                                            .firstName
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .documentIdentity
                                                        .firstName
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Apellidos
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        process.form_data.step2.documentIdentity
                                            .lastName
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .documentIdentity
                                                        .lastName
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Correo electrónico
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={process.form_data.step2.email}
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .email
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Tipo de persona
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={process.form_data.step2.personType}
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .personType
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Documento de identidad
                                    </InputLabel>
                                    <FileContainer
                                        name="Documento de identidad"
                                        url={
                                            process.form_data.step2
                                                .documentIdentity.url
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
        </Box>
    );
};

export default IdentityForm;
