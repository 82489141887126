import { api } from "@enerbit/base";
import { IPaginatedResponse, Process, SearchProcessesParams } from "../models";

export const serachProcesses = async ({
    page,
    filters,
}: SearchProcessesParams) => {
    try {
        const params: Record<string, unknown> = { size: 50, page };

        if (filters) {
            Object.keys(filters).map((key) => {
                params[key] = filters[key];
            });
        }
        const { data } = await api.get<IPaginatedResponse<Process>>(
            "/conversion/processes",
            {
                params,
            },
        );
        return data;
    } catch (_error) {
        throw new Error("ErrSearchingProcesses");
    }
};

export const getProcessDetail = async (id: string) => {
    try {
        const { data } = await api.get<Process>(`/conversion/processes/${id}`);

        return data;
    } catch (_error) {
        throw new Error("ErrGettingProcess");
    }
};
