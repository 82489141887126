import { SyntheticEvent, useState } from "react";
import { Building } from "../../../models";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    enerbitColors,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Switch,
    SxProps,
    Typography,
} from "@enerbit/base";
import AccordionButton from "../../agenda-detail/components/AccordionButton";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "./CopyButton";
import { serviceLocationNames } from "../../../common";

interface Props {
    property: Building;
    index: number;
}

const ProcessPropertyItem = ({ property, index }: Props) => {
    const [expanded, setExpanded] = useState<number | null>(null);

    const handleChange =
        (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? index : null);
        };

    const accordionSummaryStyles: SxProps = {
        // background: "#fff",
        borderRadius: expanded === index ? "4px 4px 0 0" : "4px",
        height: "auto !important",
        p: 1,
        border: `1px solid ${enerbitColors.primary[200]}`,
        // minHeight: "70px !important",
        ".MuiAccordionSummary-expandIconWrapper": {
            transform: "none !important",
        },
    };

    const accordionDetailsStyles: SxProps = {
        p: 1,
        px: 2,
        border:
            expanded === index
                ? `1px solid ${enerbitColors.primary[200]}`
                : null,
        borderRadius: "0 0 4px 4px",
    };
    return (
        <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
                my: 2,
                boxShadow: "unset !important",
                borderRadius: "16px !important",
            }}
        >
            <AccordionSummary
                expandIcon={<AccordionButton expanded={expanded === index} />}
                sx={accordionSummaryStyles}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <HomeWorkRoundedIcon color="primary" />
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontWeight: 700,
                            fontSize: "14px",
                        }}
                    >
                        Propiedad {index + 1}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsStyles}>
                <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        defaultChecked={
                                            property.hasEnergyService
                                        }
                                        disabled
                                    />
                                }
                                label={
                                    <Typography sx={{ fontSize: "16px" }}>
                                        ¿Esta propiedad cuenta con servicio de
                                        energía actualmente?
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Departamento
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={property.state}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton text={property.state} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Municipio
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={property.city}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton text={property.city} />
                                ),
                            }}
                        />
                    </Grid>
                    {property.hasEnergyService && (
                        <Grid item xs={12}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Municipio
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={property.currentNetworkOperator}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton text={property.city} />
                                    ),
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Dirección
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={property.address}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton text={property.address} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Ubicación del servicio
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={serviceLocationNames[property.service]}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton
                                        text={
                                            serviceLocationNames[
                                                property.service
                                            ] as string
                                        }
                                    />
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default ProcessPropertyItem;
