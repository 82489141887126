import {
    Box,
    enerbitColors,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Switch,
    Typography,
} from "@enerbit/base";
import { Process } from "../../../models";
import ProcessPropertyItem from "./ProcessPropertyItem";
import { generateRandomId } from "../../../utils";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "./CopyButton";
import { buildingRoleNames } from "../../../common";

interface Props {
    process: Process;
}

const PropertyForm = ({ process }: Props) => {
    return (
        <Box>
            {(!process.form_data.step1 ||
                Object.entries(process.form_data.step1).length === 0) && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: enerbitColors.primary.main,
                        }}
                    >
                        Todavía no hay información para mostrar en esta sección.
                    </Typography>
                </Box>
            )}
            {process.form_data.step1 &&
                Object.entries(process.form_data.step1).length > 0 && (
                    <Box>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontSize: "16px",
                                mt: 1,
                            }}
                        >
                            Información general
                        </Typography>
                        <Box sx={{ my: 1 }}>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    defaultChecked={
                                                        process.form_data.step1
                                                            .hasAllDocuments
                                                    }
                                                    disabled
                                                />
                                            }
                                            label={
                                                <Typography
                                                    sx={{ fontSize: "16px" }}
                                                >
                                                    ¿Cuenta con todos los
                                                    documentos?
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Rol en la propiedad
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={
                                            buildingRoleNames[
                                                process.form_data.step1.role
                                            ]
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        buildingRoleNames[
                                                            process.form_data
                                                                .step1.role
                                                        ]
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontSize: "16px",
                                my: 1,
                            }}
                        >
                            Lista de propiedades
                        </Typography>
                        {process.form_data.step1.buildings.map(
                            (item, index) => (
                                <ProcessPropertyItem
                                    property={item}
                                    key={generateRandomId()}
                                    index={index}
                                />
                            ),
                        )}
                    </Box>
                )}
        </Box>
    );
};

export default PropertyForm;

{
    /* <form>
            <FormGroup>
                <FormControlLabel
                    control={<Switch defaultChecked />}
                    label={
                        <Typography sx={{ fontSize: "16px" }}>
                            ¿Esta propiedad cuenta con servicio de energía
                            actualmente?
                        </Typography>
                    }
                />
            </FormGroup>
            <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                    <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                        Departamento
                    </InputLabel>
                    <CustomTextField
                        fullWidth
                        disabled
                        value={"Antioquia"}
                        InputProps={{
                            endAdornment: <CopyButton text={"Afinia"} />,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                        Municipio
                    </InputLabel>
                    <CustomTextField
                        fullWidth
                        disabled
                        value={"Medellin"}
                        InputProps={{
                            endAdornment: <CopyButton text={"Afinia"} />,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                        Empresa de energía
                    </InputLabel>
                    <CustomTextField
                        fullWidth
                        disabled
                        value={"Afinia"}
                        InputProps={{
                            endAdornment: <CopyButton text={"Afinia"} />,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Factura de energía
                        </InputLabel>
                        <CardPdf
                            fileInfo={{ name: "Factura_afinia", size: 1000 }}
                            onClearFile={() => {}}
                            isSuccess={true}
                            progress={100}
                            typeFile={"pdf"}
                            isClearFile={false}
                            isSeeFile={true}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                        Dirección
                    </InputLabel>
                    <CustomTextField
                        fullWidth
                        disabled
                        value={"Dg 61A # 40A - 87"}
                        InputProps={{
                            endAdornment: <CopyButton text={"Afinia"} />,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                        Ubicación del servicio
                    </InputLabel>
                    <CustomTextField
                        fullWidth
                        disabled
                        value={"Hogar"}
                        InputProps={{
                            endAdornment: <CopyButton text={"Afinia"} />,
                        }}
                    />
                </Grid>
            </Grid>
        </form> */
}
