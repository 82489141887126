import {
    Box,
    Button,
    RemoveRedEyeOutlinedIcon,
    styled,
    Typography,
} from "@enerbit/base";

interface Props {
    name: string;
    url: string;
    type?: string;
}

const FileContainer = ({ name, url, type }: Props) => {
    return (
        <Box
            sx={{
                p: "24px",
                borderRadius: "16px",
                backgroundColor: "#f2f4f7",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PdfIcon>{type ?? "PDF"}</PdfIcon>
                <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                    {name}
                </Typography>
            </Box>
            <Button
                href={url}
                target="_blank"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none", // Elimina subrayado de links si es necesario
                }}
            >
                <RemoveRedEyeOutlinedIcon />
            </Button>
        </Box>
    );
};

export default FileContainer;

const PdfIcon = styled("div")`
  width: 45px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f04438;
  border-radius: 4px;
  color: white;
`;
