import { Box, enerbitColors, Grid, Typography } from "@enerbit/base";
import { Process } from "../../../models";

interface Props {
    process: Process;
}

const Checkout = ({ process }: Props) => {
    return (
        <Box>
            {(!process.form_data.step3 ||
                Object.entries(process.form_data.step3).length === 0) && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: enerbitColors.primary.main,
                        }}
                    >
                        Todavía no hay información para mostrar en esta sección.
                    </Typography>
                </Box>
            )}
            {process.form_data.step3 &&
                Object.entries(process.form_data.step3).length > 0 && (
                    <>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontSize: "16px",
                                mt: 1,
                            }}
                        >
                            Firma
                        </Typography>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xs={12}>
                                <Box>
                                    <img
                                        src={process.form_data.step3.signature}
                                        alt="Signature"
                                        width="600px"
                                        style={{ objectFit: "contain" }}
                                        height="200px"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
        </Box>
    );
};

export default Checkout;
