import {
    Box,
    CircularProgress,
    CustomAlert,
    enerbitColors,
    Typography,
} from "@enerbit/base";
import Backbutton from "../../components/Buttons/back-button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAgendaStore } from "../../store";
import GeneralInfo from "./components/GeneralInfo";
import Properties from "./components/Properties";
import { agendaStatus } from "../list/components/AgendaTable";
import StatusTag from "../../components/container/status-tag";
import Processes from "./components/Processes";

const AgendaDetail = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const { loading, error, getAgendaDetail, agenda } = useAgendaStore(
        (state) => state,
    );

    const handleBack = () => {
        navigate("/");
    };

    useEffect(() => {
        if (!id) return;

        getAgendaDetail(id);
    }, [id]);
    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Backbutton onClick={handleBack} />
                <Box>
                    <Typography sx={{ color: enerbitColors.neutral[700] }}>
                        Agenda
                    </Typography>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "28px",
                            fontWeight: 700,
                        }}
                    >
                        {id}
                    </Typography>
                </Box>
                {agenda && (
                    <StatusTag
                        fontcolor={agendaStatus[agenda.status].font}
                        bgcolor={agendaStatus[agenda.status].bgcolor}
                    >
                        {agendaStatus[agenda.status].label}
                    </StatusTag>
                )}
            </Box>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        my: 2,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        my: 2,
                    }}
                >
                    <CustomAlert
                        severity="error"
                        text="Error consultando detalle de la agenda"
                        onClose={() => {}}
                    />
                </Box>
            )}
            {!error && !loading && agenda && (
                <>
                    <Box sx={{ my: 2 }}>
                        <GeneralInfo agenda={agenda} />
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Properties agenda={agenda} />
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Processes agenda={agenda} />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AgendaDetail;
