import { ContentCopyIcon, IconButton, InputAdornment } from "@enerbit/base";
import { handleCopy } from "../../../utils";

const CopyButton = ({ text }: { text: string }) => {
    return (
        <InputAdornment position="end">
            <IconButton
                onClick={() => {
                    handleCopy(text);
                }}
                size="small"
            >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
        </InputAdornment>
    );
};

export default CopyButton;
