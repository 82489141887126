import { Box, enerbitColors, Typography } from "@enerbit/base";
import { useState } from "react";
import { StyledTabs } from "../../components/Tabs/StyledTabs";
import { StyledTab } from "../../components/Tabs/StyledTab";
import ProcessTable from "./components/ProcessTable";
import { TabPanel } from "../../components/Tabs/TabPanel";
import SchedulesTable from "./components/AgendaTable";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ConversionList = () => {
    const [tab, setTab] = useState<number>(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Box>
            <Typography
                sx={{
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                    fontSize: "28px",
                }}
            >
                Conversión
            </Typography>
            <Box sx={{ mt: 3 }}>
                <StyledTabs value={tab} onChange={handleChange}>
                    <StyledTab label={<Box>Procesos</Box>} {...a11yProps(0)} />
                    <StyledTab label={<Box>Agenda</Box>} {...a11yProps(1)} />
                </StyledTabs>
                <TabPanel value={tab} index={0}>
                    <ProcessTable />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <SchedulesTable />
                </TabPanel>
            </Box>
        </Box>
    );
};

export default ConversionList;
