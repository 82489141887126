import { create } from "zustand";
import { Agenda, IAgendaDetail, SearchAgendaParams } from "../models";
import { getAgendaDetail, searchAgendasService } from "../services";

interface AgendaStore {
  loading: boolean;
  error: boolean;
  agendas: Agenda[];
  agenda: IAgendaDetail | null;
  totalPages: number;
  currentPage: number;
  totalAgendas: number;
  searchAgendas: (props: SearchAgendaParams) => Promise<void>;
  getAgendaDetail: (id: string) => Promise<void>;
}

export const useAgendaStore = create<AgendaStore>()((set) => ({
  loading: false,
  error: false,
  agendas: [],
  totalAgendas: 0,
  totalPages: 0,
  currentPage: 0,
  agenda: null,
  searchAgendas: async ({ page, filters }: SearchAgendaParams) => {
    try {
      set({ loading: true, error: false });
      const res = await searchAgendasService({ page, filters });

      set({
        agendas: res.items,
        currentPage: res.page,
        totalAgendas: res.total,
        totalPages: res.pages,
      });
    } catch (_error) {
      set({ error: true });
    } finally {
      set({ loading: false });
    }
  },
  getAgendaDetail: async (id: string) => {
    try {
      set({ loading: true, error: false });
      const res = await getAgendaDetail(id);
      set({ agenda: res });
    } catch (_error) {
      set({ error: true });
    } finally {
      set({ loading: false });
    }
  },
}));
